export const daoConfig = {
  DAO_ADDRESS:
    "migaloo10gj7p9tz9ncjk7fm7tmlax7q6pyljfrawjxjfs09a7e7g933sj0q7yeadc",
  DAO_ADDRESS_TESTNET:
    "migaloo14ke63efdjcjh2w6f4q7h4au5ccuktfw0t7ajtx8n6zu0wpr00a8skdv03n",
  OPHIR_DENOM:
    "factory/migaloo1t862qdu9mj5hr3j727247acypym3ej47axu22rrapm4tqlcpuseqltxwq5/ophir",
  OPHIR_DENOM_TESNET:
    "factory/migaloo17c5ped2d24ewx9964ul6z2jlhzqtz5gvvg80z6x9dpe086v9026qfznq2e/daoophir",
  DAO_STAKING_CONTRACT_ADDRESS:
    "migaloo1kv72vwfhq523yvh0gwyxd4nc7cl5pq32v9jt5w2tn57qtn57g53sghgkuh",
  DAO_STAKING_CONTRACT_ADDRESS_TESTNET:
    "migaloo1d6kqpt8p8c49zegvyn4v3jeqgwskdsp03m7hkcqf54eavexz8k3qjrp98r",
  DAO_OPHIR_STAKING_REWARDS_CONTRACT_ADDRESS:
    "migaloo1234uqhtq3mr723a446ph5fel4l7qwmgj08fe00nnu3gd2mfh60tqnc0xq7",
  DAO_WBTC_STAKING_REWARDS_CONTRACT_ADDRESS:
    "migaloo1waqzv34jg7nq4uqdef4yvhddc85n9daurrvf2m4cx797x7sdxlmq73sz39",
  DAO_VAULT_ADDRESS:
    "migaloo14gu2xfk4m3x64nfkv9cvvjgmv2ymwhps7fwemk29x32k2qhdrmdsp9y2wu",
  DAO_VAULT_ADDRESS_TESTNET:
    "migaloo14gu2xfk4m3x64nfkv9cvvjgmv2ymwhps7fwemk29x32k2qhdrmdsp9y2wu",
  DAO_MINE_ADDRESS:
    "migaloo1dpchsx70fe6gu9ljtnknsvd2dx9u7ztrxz9dr6ypfkj4fvv0re6qkdrwkh",
  DAO_MINE_ADDRESS_TESTNET: "migaloo17ephyl7pxx7hrauhu6guf62z7nrtszj23wwdt8",

  DAO_REWARDS_BUCKET_ADDRESS:"migaloo1234uqhtq3mr723a446ph5fel4l7qwmgj08fe00nnu3gd2mfh60tqnc0xq7",
  // REDEEM Contract Addresses
  CONTRACT_ADDRESS:
    "migaloo10p9ttf976c4q7czknd3z7saejsmx0uwvy4lgzyg09jmtq6up9e3s3wga9m",
  CONTRACT_ADDRESS_TESTNET:
    "migaloo1ypk96ekjz0w6q2hlm6khaya9d2agrjw96lcxnczh7yvyrwjqy38st7n82t",

  //BONDS Contract Addresses
  BONDS_CONTRACT_ADDRESS: "migaloo1tap5zyztwtmwrg90pwynjfzle9sxes3057qvdwers5js8exg2zaqn4vvxq",
  BONDS_NFT_CONTRACT_ADDRESS: "migaloo1ns5zz5vh4esf3mjr7ha5xytugtd3k80jdzjmde6ew3py8qhf0fgskvvg0m",

  BONDS_CONTRACT_ADDRESS_TESTNET: "migaloo1vara8gy89dq4pv7mnwdumvxpc8dlz74culwjf49fhq7sml8mpsrqxkkf3v",
  BONDS_NFT_CONTRACT_ADDRESS_TESTNET: "migaloo1ux3exy8lm6ppurak2zl8zr4p50mx4zzdfapwftv6zzwpm4mgf8csda6xpn",
};
